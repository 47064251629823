<template>
  <div>
    <v-container>
      <div class="d-flex justify-center" v-if="loading">
        檔案匯出中，請稍後⋯⋯
      </div>
      <div class="d-flex justify-center" v-else>
        檔案匯出完成，可以關閉此頁面。
      </div>
    </v-container>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  data: () => ({
    loading: true,
  }),
  computed: {},
  created() {
    this.startExport();
  },
  methods: {
    startExport() {
      if (!this.$route.query.data) {
        return this.$router.replace({ name: "home" });
      }

      const data = JSON.parse(decodeURIComponent(this.$route.query.data));
      const { list, folderName } = data
      let arr = [];
      if (list) {
        const { header, content } = list;
        const headerArr = header.map((item) => item.text);
        arr.push(headerArr);

        for (let i in content) {
          const data = content[i];
          const dataArr = data.map((item) => item.text);
          arr.push(dataArr);
        }
      }
      const ws = XLSX.utils.aoa_to_sheet(arr);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      try {
        XLSX.writeFile(
          wb,
          `${folderName} .xlsx`
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false;
      }

    },
  },
};

</script>